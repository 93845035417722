"use client";
import { useEffect } from "react";
import { getCountryCodeAction } from "../actions/staticForms";

const ClientSideLatLong = ({ children }) => {
  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const lat = position.coords.latitude;
            const long = position.coords.longitude;

            // Save to local storage
            localStorage.setItem("userLatitude", lat);
            localStorage.setItem("userLongitude", long);

            await getCountryCodeAction(lat, long);
          },
          (err) => {
            console.log("Location permission denied or not available.");
          }
        );
      } else {
        console.log("Location permission denied or not available.");
      }
    };

    // Call getLocation on component load
    getLocation();
  }, []);

  return null;
};

export default ClientSideLatLong;

import styled from "@emotion/styled";
import React from "react";
export const SearchIcon = ({
  size = 25,
  strokeWidth = 1.5,
  width,
  height,
  className,
  ...props
}) => (
   <div className={`searchIconWrap w-[44px] md:w-[70px] ${className ? className : ""}`}>
     <svg
        width={size}
        height={size}
        viewBox="0 0 24 25" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        className={`svg ${className ? className : ""}`}
        {...props}
        >
        <circle cx="10" cy="10.5" r="6" stroke="#BDBDBD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.5 15L19 19.5" stroke="#BDBDBD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
   </div>
);

import React from "react";
export const TwitterIcon = ({
  size = 26,
//   strokeWidth = 1.5,
  width,
  height,
  ...props
}) => (
    <svg 
        width="26" 
        height="24"
        viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.8522 1.9043H23.5068L15.5226 10.3278L24.9154 21.7903H17.5609L11.8006 14.8383L5.20947 21.7903H1.55267L10.0926 12.7804L1.08203 1.9043H8.62323L13.83 8.25863L19.8522 1.9043ZM18.5695 19.7711H20.5946L7.52287 3.81743H5.34978L18.5695 19.7711Z" fill="white"/>
    </svg>
);


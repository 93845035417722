"use client";
import React, { useEffect, useState } from "react";
import { Autocomplete, AutocompleteItem } from "@nextui-org/react";
import { SearchIcon } from "./molecules/SearchIcon";
import { useParams, useRouter } from "next/navigation";
import { useDictionary } from "../context/dictionaryProvider";
import { fetchFilteredCourses } from "../actions/courses";
import { debounce, getLink } from "../lib/util";
import { useAsyncList } from "@react-stately/data";

export default function CoursesAutocomplete() {
  const dict = useDictionary();
  const params = useParams();
  const router = useRouter();

  const [inputFocused, setInputFocused] = useState(false);

  let list = useAsyncList({
    async load({ signal, filterText }) {
      if (!inputFocused) return { items: [] }; // No API call until input is focused
      let res = await fetchFilteredCourses(
        "",
        {
          query: filterText,
          show_on_search: true,
          signal,
        },
        params
      );
      return {
        items: res?.data || [],
      };
    },
  });

  // Use effect to trigger the initial API call when the input is focused
  useEffect(() => {
    if (inputFocused) {
      list.reload(); // Trigger the first API call once the input is focused
    }
  }, [inputFocused]); // Run this effect when inputFocused changes to true

  const handleFocus = () => {
    setInputFocused(true);
  };

  const handleChange = (key) => {
    if (key) router.push(key);
  };

  return (
    <Autocomplete
      className="max-w-xs rounded-full"
      inputValue={list.filterText}
      isLoading={list.isLoading}
      items={list.items}
      aria-label={dict?.courses?.search}
      placeholder={dict?.courses?.search}
      variant="bordered"
      onInputChange={(value) => debounce(list.setFilterText(value), 2000)}
      onFocus={handleFocus} // Trigger the first API call on focus
      content="p-0"
      base="p-0"
      startContent={<SearchIcon />}
      endContent={null}
      size="lg"
      inputProps={{
        classNames: {
          content: "p-0",
          input: "text-xs 2xl:text-sm",
          inputWrapper: "rounded-full min-h-10 h-10",
          base: "p-0",
        },
      }}
      onSelectionChange={handleChange}
    >
      {(item) => (
        <AutocompleteItem
          key={getLink(`/${params.lang}/${item.slug}`)}
          className="capitalize"
          classNames={{
            label: "labelClass",
            base: "autoComplete",
            description: "whitespace-normal",
          }}
        >
          {item.course_name}
        </AutocompleteItem>
      )}
    </Autocomplete>
  );
}

import React from "react";
export const WhatsAppIcon = ({
  size = 28,
  strokeWidth = 1.5,
  width,
  height,
  className,
  ...props
}) => (
    <svg 
        width={size}
        height={size} 
        viewBox="0 0 28 29" 
        fill="none" 
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        >
        <g clipPath="url(#clip0_1294_1639)" filter="url(#filter0_i_1294_1639)">
        <path d="M0 28.5L2.00715 21.0369C0.388434 18.1297 -0.125849 14.7372 0.558675 11.4819C1.2432 8.22669 3.08061 5.32706 5.73375 3.31515C8.38689 1.30323 11.6778 0.313969 15.0025 0.528903C18.3273 0.743836 21.4629 2.14854 23.834 4.48526C26.2051 6.82197 27.6526 9.93396 27.9109 13.2501C28.1692 16.5663 27.2209 19.8643 25.2402 22.5389C23.2594 25.2134 20.379 27.0851 17.1275 27.8106C13.876 28.5361 10.4714 28.0666 7.53867 26.4883L0 28.5ZM7.90217 23.6956L8.3684 23.9717C10.4927 25.2287 12.9738 25.7489 15.4251 25.4511C17.8764 25.1534 20.1602 24.0545 21.9206 22.3257C23.681 20.5968 24.8192 18.3352 25.1576 15.8931C25.4961 13.4511 25.0159 10.9659 23.7919 8.82478C22.5679 6.68366 20.6688 5.0069 18.3906 4.05581C16.1123 3.10473 13.583 2.93273 11.1966 3.56662C8.81027 4.20052 6.70098 5.60471 5.19745 7.56035C3.69393 9.516 2.88063 11.9133 2.88429 14.3785C2.8823 16.4227 3.44856 18.4272 4.52004 20.1691L4.81242 20.6504L3.69032 24.8158L7.90217 23.6956Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.2182 16.2949C18.9449 16.0748 18.6249 15.9199 18.2825 15.842C17.9402 15.764 17.5845 15.7651 17.2427 15.8452C16.729 16.0582 16.3971 16.8629 16.0652 17.2652C15.9952 17.3617 15.8924 17.4293 15.776 17.4555C15.6596 17.4816 15.5377 17.4644 15.4331 17.4072C13.5526 16.6719 11.9764 15.3235 10.9604 13.581C10.8738 13.4723 10.8328 13.3342 10.846 13.1959C10.8593 13.0576 10.9258 12.9298 11.0315 12.8394C11.4017 12.4736 11.6735 12.0205 11.8218 11.522C11.8547 10.9721 11.7285 10.4244 11.4583 9.94416C11.2493 9.27087 10.8517 8.67135 10.3124 8.21645C10.0343 8.09155 9.72588 8.04968 9.42441 8.09587C9.12295 8.14207 8.84131 8.27436 8.61348 8.47679C8.21795 8.81747 7.90401 9.24239 7.69477 9.72025C7.48553 10.1981 7.38632 10.7168 7.40445 11.238C7.40567 11.5307 7.44282 11.8221 7.51508 12.1058C7.69857 12.7873 7.98076 13.4384 8.35271 14.0386C8.62106 14.4983 8.91386 14.9434 9.22985 15.3718C10.2568 16.7792 11.5476 17.9742 13.0308 18.8904C13.7751 19.3559 14.5705 19.7345 15.4014 20.0185C16.2646 20.4091 17.2176 20.5591 18.1593 20.4524C18.6958 20.3713 19.2042 20.1598 19.6396 19.8366C20.075 19.5133 20.4242 19.0881 20.6564 18.5985C20.7928 18.3027 20.8342 17.972 20.7749 17.6518C20.6327 16.997 19.7555 16.6104 19.2182 16.2949Z" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_i_1294_1639" x="0" y="0.5" width="28" height="28.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="0.5"/>
        <feGaussianBlur stdDeviation="1.25"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1294_1639"/>
        </filter>
        <clipPath id="clip0_1294_1639">
        <rect width="28" height="28" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
    </svg>
);


import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/app/[lang]/bitrixScript.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/app/[lang]/globals.css");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/layout.jsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/lp-frontend/src/app/[lang]/nextAuthSessionProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/ubuntu/lp-frontend/src/app/[lang]/nextUiProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/lp-frontend/src/components/ClientSideLatLong.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/Footer.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/Header/Header.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["WrapperOuter"] */ "/home/ubuntu/lp-frontend/src/components/molecules/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/lp-frontend/src/context/dictionaryProvider.tsx");

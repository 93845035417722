import React from "react";

const IndiaFlag = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72 48"
      width="36"
      height="24"
    >
      <rect fill="#FF9933" width="72" height="16" />
      <rect fill="#FFFFFF" y="16" width="72" height="16" />
      <rect fill="#138808" y="32" width="72" height="16" />
      <circle
        cx="36"
        cy="24"
        r="6"
        fill="none"
        stroke="#000080"
        strokeWidth="0.6"
      />
      <path
        d="M36 18 v1.5 M36 28.5 v1.5 M30 24 h-1.5 M43.5 24 h1.5 M32.1 20.1 l-1.05-1.05 M41.7 29.7 l1.05 1.05 M41.7 20.1 l1.05-1.05 M32.1 29.7 l-1.05 1.05"
        stroke="#000080"
        strokeWidth="0.3"
      />
      <circle cx="36" cy="24" r="1.5" fill="#000080" />
    </svg>
  );
};

export default IndiaFlag;

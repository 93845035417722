import endpoints from "../../lib/endpoints";
import getRequest from "../../lib/fetchClient";
import { baseUrl, buildPaginationQuery } from "../../lib/util";

export const fetchFilteredCourses = async (type = "", searchParams, params) => {
  try {
    let url = "";

    if (searchParams?.brands) {
      searchParams?.brands
        .split(",")
        ?.forEach(
          (param, index) =>
            (url += `&filters[brands][slug][$in][${index}]=${param}`)
        );
    }

    if (searchParams?.affiliations) {
      searchParams?.affiliations
        .split(",")
        ?.forEach(
          (param, index) =>
            (url += `&filters[affiliations][slug][$in][${index}]=${param}`)
        );
    }

    if (type) {
      url += "&filters[category][slug][$eq]=" + type;
    }

    if (searchParams?.sort === "alphabets") {
      url += "&sort=course_name:asc";
    } else if (searchParams?.sort === "new") {
      url += "&sort=createdAt:desc";
    } else if (searchParams?.sort === "popular") {
      url += "&sort=createdAt:asc";
    } else if (searchParams?.sort === "menu_showing_order") {
      if (type === "") {
        url += "&sort=menu_showing_order:desc";
      }
    }

    if (params?.showMenu) {
      url += "&filters[show_in_menu][$eq]=true";
    }

    if (searchParams?.show_on_search) {
      url += "&filters[show_on_search][$eq]=true";
    }

    if (searchParams?.query) {
      url += "&filters[course_name][$contains]=" + searchParams?.query;
    }

    url += buildPaginationQuery(searchParams);

    const option = {
      lang: params?.lang,
    };
    if (searchParams?.signal) {
      option["signal"] = searchParams?.signal;
    }

    const res = await getRequest(baseUrl + endpoints.courses + url, option);
    return res;
  } catch (error) {
    console.log(error?.message);
  }
};

export const getAllCourses = async (params) => {
  try {
    const res = await getRequest(baseUrl + endpoints.allCourses, {
      cache: "no-cache",
      lang: params?.lang,
    });
    return res;
  } catch (error) {
    console.log(`Error fetching all courses : `, error?.message);
    return null;
  }
};

export const getAllCoursesPayNow = async (params) => {
  try {
    const res = await getRequest(baseUrl + endpoints.allCoursesPayNow, {
      cache: "no-cache",
      lang: params?.lang,
    });
    return res;
  } catch (error) {
    console.log(`Error fetching all courses : `, error?.message);
    return null;
  }
};

"use client";
import { Lateef } from "next/font/google";
import endpoints from "../../lib/endpoints";
import { baseUrl, nextPublicUrl } from "../../lib/util";

export const staticFormsActions = async (formName, data) => {
  try {
    const url = baseUrl + endpoints.ezForms;
    const req = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ formName, formData: data }),
    });

    const res = await req.json();

    return { status: true, res };
  } catch (error) {
    console.log(error);
  }
};

export const getCountryCodeAction = async (lat, lng) => {
  try {
    // If cache exists and is not expired, use it
    const now = new Date().getTime();

    // If no valid cache, fetch from the API
    const response = await fetch(`${nextPublicUrl}/api/getCountryCode`, {
      method: "POST",
      next: {
        revalidate: 0,
      },
      body: JSON.stringify({
        lat,
        lng,
      }),
    });

    const data = await response.json();
    const countryCode = data.countryCode;

    // Cache the fetched country code and set expiration (1 hours)
    localStorage.removeItem("countryCode");
    localStorage.setItem("countryCode", countryCode);

    return countryCode;
  } catch (error) {
    console.log(error);
    return null;
  }
};

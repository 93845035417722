"use client";
import styled from "@emotion/styled";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { useParams } from "next/navigation";
import React, { useEffect, useState } from "react";
import { getFooterMenus } from "../actions/clientSide";
import IndiaFlag from "../components/molecules/Socials/IndiaFlag";
import UkFlag from "../components/molecules/Socials/UkFlag";
import Usflag from "../components/molecules/Socials/UsFlag";
import DubaiFlag from "./molecules/Socials/DubaiFlag";
import { useDictionary } from "../context/dictionaryProvider";
import { getImageUrlNew, getLink, isCompleteUrl } from "../lib/util";
import { ContentContainer } from "./molecules";
import { CallIcon } from "./molecules/Socials/CallIcon";
import { EmailIcon } from "./molecules/Socials/EmailIcon";
import { FacebookIcon } from "./molecules/Socials/FacebookIcon";
import { InstaIcon } from "./molecules/Socials/InstaIcon";
import { LinkedinIcon } from "./molecules/Socials/LinkedinIcon";
import { PhoneIcon } from "./molecules/Socials/PhoneIcon";
import { TwitterIcon } from "./molecules/Socials/TwitterIcon";
import { WhatsAppIcon } from "./molecules/WhatsAppIcon";

const NewsletterForm = dynamic(() => import("./newsletterForm"), {
  ssr: false,
});

export const Footer = () => {
  const dict = useDictionary();
  const params = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchFooter = async () => {
      try {
        const response = await getFooterMenus(params);
        setData(response);
      } catch (error) {
        console.log(error);
      }
    };
    fetchFooter();
  }, [params]);

  const contactUs = [
    {
      icon: <EmailIcon />,
      text: "info@learnerspoint.org",
      href: "mailto:",
      id: "contact-email",
    },
    {
      icon: <WhatsAppIcon width={24} height={24} />,
      text: "+971566335515",
      href: "https://api.whatsapp.com/send/?phone=+971566335515&text=Hello",
      id: "whatsapp-button",
    },
    {
      icon: <PhoneIcon />,
      text: "+971 (04) 4038000",
      href: "tel:+971 (04) 4038000",
      id: "contact-telephone",
    },
    {
      icon: <DubaiFlag />,
      text: "+971 (04) 4038000",
      href: "tel:+971 (04) 4038000",
      id: "contact-dubai",
    },
    {
      icon: <Usflag />,
      text: "+1 347 637 6133",
      href: "tel:+1 347 637 6133",
      id: "contact-usa",
    },
    {
      icon: <UkFlag />,
      text: "+44 20 4524 4199",
      href: "tel:+44 20 4524 4199",
      id: "contact-uk",
    },
    {
      icon: <IndiaFlag />,
      text: "+91 97310 58471",
      href: "tel:+91 97310 58471",
      id: "contact-india",
    },
  ];
  const socialsIcon = {
    facebook: <FacebookIcon />,
    twitter: <TwitterIcon />,
    instagram: <InstaIcon />,
    linkedIn: <LinkedinIcon />,
  };

  return (
    <Wrapper className="footer px-8 xl:px-0 py-20">
      <ContentContainer>
        <div className="grid grid-flow-row lg:grid-flow-col grid-cols-1 sm:grid-cols-2 lg:grid-cols-[25%_1fr_1fr_1fr] gap-12 lg:gap-20">
          <Columns>
            <Image
              priority={true}
              className="relative mb-10"
              src={getImageUrlNew(data?.logo)}
              alt="logo"
              width={100}
              height={100}
            />
            <p className="mb-3 font-semibold text-lg">{dict?.footer.address}</p>
            {data?.address && <Address>{data?.address}</Address>}
            <NewsletterForm />
          </Columns>
          {data?.categories_list?.data?.length > 0 && (
            <Columns>
              <p className="mb-5 font-semibold text-lg">
                {/* {dict?.footer.courses} */}
                {dict?.footer.categories}
              </p>
              <ul columnsrole="list" className="">
                {data?.categories_list?.data
                  ?.slice(0, 10)
                  .map((item, index) => (
                    <li key={index} className="pb-3 capitalize">
                      <Link
                        href={getLink(
                          `/${params?.lang}/browsecourses/${item?.slug || ""}`
                        )}
                      >
                        {item?.name}
                      </Link>
                    </li>
                  ))}
              </ul>
            </Columns>
          )}
          {data?.quick_links?.length > 0 && (
            <Columns>
              <p className="mb-5 font-semibold text-lg">
                {dict?.footer.quickLinks}
              </p>
              <ul columnsrole="list">
                {data?.quick_links.map((item, index) => (
                  <li key={index} className="pb-3">
                    <Link
                      href={
                        isCompleteUrl(item?.url)
                          ? item?.url
                          : getLink(`/${params?.lang}/${item?.url || ""}`)
                      }
                      className="pb-3 text-white"
                    >
                      {item?.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </Columns>
          )}
          <Columns>
            <p className="mb-5 font-semibold text-lg">
              {dict?.footer.contactUs}
            </p>
            <ul columnsrole="list" className="addressIcons">
              {contactUs.map((item, index) => (
                <li key={index} className="addressIconsItems">
                  <Link href={item.href} className="flex gap-4" id={item?.id}>
                    <span className="w-10">{item.icon} </span>
                    <span className="text-start">{item.text}</span>
                  </Link>
                </li>
              ))}
            </ul>
            {data?.social_media?.length > 0 && (
              <StackBottom>
                <p className="font-semibold text-lg">
                  {dict?.footer.stayConnected}
                </p>
                <ul columnsrole="list" className="socialMenus">
                  {data?.social_media?.map((item, index) => (
                    <React.Fragment key={index}>
                      {socialsIcon[item?.iconName] && (
                        <li className="socialItems">
                          <Link href={item?.url}>
                            {socialsIcon[item.iconName]}
                          </Link>
                        </li>
                      )}
                    </React.Fragment>
                  ))}
                </ul>
              </StackBottom>
            )}
          </Columns>
        </div>
        <Stack
          className="item-center border-t-1 border-[#F9F5EC66] pt-5 mt-16 justify-center text-center"
          width="1fr"
        >
          <Link href="/privacy-policy" className="">
            {dict?.footer?.privacyPolicy}
          </Link>
        </Stack>
      </ContentContainer>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div`
  background: #000;
  color: #fff;
`;
const Stack = styled.div`
  display: grid;
  grid-auto-flow: ${(props) => (props.flow ? props.flow : "column")};
  grid-template-columns: ${(props) =>
    props.width ? props.width : "25% 1fr 1fr 1fr"};
  gap: ${(props) => (props.gap ? props.gap : "")};
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
  }
  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-flow: row;
  }
`;
const Columns = styled.div`
  position: relative;
`;
const Address = styled.div`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.42px;
  padding: 0 3rem 0 0;
`;
const StackBottom = styled.div`
  position: relative;
  margin-top: 60px;
`;

import React from 'react'

const UsFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7410 3900" width="36" height="24">
    <rect width="7410" height="3900" fill="#b22234"/>
    <path d="M0,450 h7410 v300 h-7410 z" fill="#fff"/>
    <path d="M0,1050 h7410 v300 h-7410 z" fill="#fff"/>
    <path d="M0,1650 h7410 v300 h-7410 z" fill="#fff"/>
    <path d="M0,2250 h7410 v300 h-7410 z" fill="#fff"/>
    <path d="M0,2850 h7410 v300 h-7410 z" fill="#fff"/>
    <path d="M0,3450 h7410 v300 h-7410 z" fill="#fff"/>
    <rect width="2964" height="2100" fill="#3c3b6e"/>
    <g fill="#fff">
      <g id="s18">
        <g id="s9">
          <g id="s5">
            <g id="s4">
              <path id="s" d="M247,90 317,315 112,180 h310 L177,315 z"/>
              <use xlinkHref="#s" y="420"/>
              <use xlinkHref="#s" y="840"/>
              <use xlinkHref="#s" y="1260"/>
            </g>
            <use xlinkHref="#s" y="1680"/>
          </g>
          <use xlinkHref="#s4" x="247" y="210"/>
        </g>
        <use xlinkHref="#s9" x="494"/>
      </g>
      <use xlinkHref="#s18" x="988"/>
      <use xlinkHref="#s9" x="1976"/>
      <use xlinkHref="#s5" x="2470"/>
    </g>
  </svg>
  
  )
}

export default UsFlag
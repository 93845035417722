import React from "react";
export const EmailIcon = ({
  size = 28,
  strokeWidth = 1.5,
  width,
  height,
  ...props
}) => (
    <svg 
        width={size} 
        height={size}
        viewBox="0 0 28 28" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        >
        <path d="M4 7.00005L10.2 11.65C11.2667 12.45 12.7333 12.45 13.8 11.65L20 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <rect x="3" y="5" width="18" height="14" rx="2" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>

);


"use client";
import { useState, useEffect } from "react";
import Script from "next/script";

const BitrixScript = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    // Delay the script load by 5 seconds
    const timeout = setTimeout(() => {
      setIsScriptLoaded(true);
    }, 5000);

    return () => clearTimeout(timeout); // Cleanup on unmount
  }, []);

  return (
    <>
      {isScriptLoaded && (
        <Script
          id="bitrix24-script"
          src="https://cdn.bitrix24.com/b12529265/crm/site_button/loader_3_zyjjkf.js"
          strategy="lazyOnload" // Loads script lazily after the page is interactive
          onLoad={console.log("LOADED SCRIPT")}
        />
      )}
      {/* <Script
        src="https://cdn.voiceflow.com/widget/bundle.mjs"
        type="text/javascript"
        onLoad={() => {
          window.voiceflow.chat
            .load({
              verify: { projectID: "667478e3f19f313963bb1525" },
              url: "https://general-runtime.voiceflow.com",
              versionID: "production",
            })
            .then(() => {
              setTimeout(() => {
                window.voiceflow.chat.proactive.push({
                  type: "text",
                  payload: { message: "Hi, I am Fatima!" },
                });
              }, 1500);

              setTimeout(() => {
                window.voiceflow.chat.proactive.push({
                  type: "text",
                  payload: {
                    message: "Ask me your question, I will be glad to help!",
                  },
                });
              }, 3000);
            });
        }}
        strategy="afterInteractive"
      /> */}
    </>
  );
};

export default BitrixScript;

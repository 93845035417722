import React from "react";
export const PhoneIcon = ({
  size = 24,
  strokeWidth = 1.5,
  width,
  height,
  ...props
}) => (
    <svg 
        width={size} 
        height={size} 
        viewBox="0 0 24 24" 
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 17H13M9 21H15C16.6569 21 18 19.6569 18 18V6C18 4.34315 16.6569 3 15 3H9C7.34315 3 6 4.34315 6 6V18C6 19.6569 7.34315 21 9 21Z" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>


);

